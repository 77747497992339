* {
  background-color: #c5a586;
}

.main-div {
  text-align: center;
}

input, textarea {
  background-color: #f8f4ec;
  border: none;
}

a {
  color: #000000;
  text-decoration: none;
}

.nav-bar {
  justify-content: space-evenly;
}

h1 {
  text-align: center;
}