.message-area * {
    vertical-align: top;
}

li {
    margin-right: 2.5%;
    margin-left: 2.5%;
    margin-bottom: 2.5%;
    display:inline;
    width: 100%;
}